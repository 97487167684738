import React from 'react'
import { Router } from '@reach/router'

import UserDetailsPage from '~components/pages/user'

const UsersPage: React.FC = () => {
  return (
    <Router basepath="/users">
      <UserDetailsPage path="/:id" />
    </Router>
  )
}

export default UsersPage
