import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { Grid, GridItem, Text } from '@chakra-ui/react'

import Seo from '~components/shared/seo'
import Heading from '~components/shared/heading'
import ProfileSection from '~components/pages/account/profile-section'
import ContentBox from '~components/shared/content-box'
import ContentBoxTitle from '~components/shared/content-box-title'
import { useUserQuery } from '~graphql/generated'

interface Props extends RouteComponentProps {
  id?: string
}

const UserDetailsPage: React.FC<Props> = ({ id = '' }) => {
  const { data, loading } = useUserQuery({ variables: { id } })

  return (
    <>
      <Seo title={`User #${id}`} />
      <Heading isLoading={loading}>User #{id}</Heading>
      <Grid gap={[4, 6]}>
        <GridItem>
          <ProfileSection
            loading={loading}
            image={data?.user?.profile_img?.url}
            email={data?.user?.email}
            phone={data?.user?.phone || ''}
            firstName={data?.user?.first_name || ''}
            lastName={data?.user?.last_name || ''}
          />
        </GridItem>
        <Grid
          gap={[4, 6]}
          templateColumns={[
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
            'repeat(4, 1fr)',
            'repeat(2, 1fr)',
            'repeat(4, 1fr)',
          ]}
        >
          <GridItem>
            <ContentBox isLoading={loading}>
              <ContentBoxTitle>Primary Badge</ContentBoxTitle>
              <Text>{data?.user?.primary_badge?.name}</Text>
            </ContentBox>
          </GridItem>
          <GridItem>
            <ContentBox isLoading={loading}>
              <ContentBoxTitle>Subscription</ContentBoxTitle>
              <Text>{data?.user?.subscription?.name}</Text>
            </ContentBox>
          </GridItem>
          <GridItem>
            <ContentBox isLoading={loading}>
              <ContentBoxTitle>Membership</ContentBoxTitle>
              <Text>{data?.user?.membership}</Text>
            </ContentBox>
          </GridItem>
          <GridItem>
            <ContentBox isLoading={loading}>
              <ContentBoxTitle>Balance</ContentBoxTitle>
              <Text>{data?.user?.credit_balance} credits</Text>
            </ContentBox>
          </GridItem>
        </Grid>
      </Grid>
    </>
  )
}

export default UserDetailsPage
